import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IUserGet } from '../../../types/Interface';
import { getItemLS, getItemObjLS, getItemStrLS, setItemStrLS, getTeachersLS } from '../../../utils/getUserLS';

interface IUserInfo {
  id: number
  name: string
  login: string
  email: string
  phone: string
  birthdate: string
  link: string
  isAdmin: boolean
  roles: Array<string>
  student: {
    subscriptionsList: string,
    subscriptions: Array<{
      id: number
      balance: number
      isActive: boolean
      numberSubscription: string
    }>
  }
}

const initialState = {
  infoStudent: {} as IUserInfo,
  render: 0,
}

export const noticeStudentSlice = createSlice({
  name: 'noticeStudent',
  initialState,
  reducers: {
		setInfoStudent: (state, action: PayloadAction<any>) => {
      state.infoStudent = action.payload
		},
    setRender: (state, action: PayloadAction<number>) => {
      state.render = action.payload
		},
  },
})

export const { 
  setInfoStudent,
  setRender,
} = noticeStudentSlice.actions

export default noticeStudentSlice.reducer
