export const getUserLS = () => {
  const data = localStorage.getItem('invitesR');
  return data ? JSON.parse(data) : []
}

export const getUserMasLS = () => {
  const data = localStorage.getItem('invitesMasR');
  return data ? JSON.parse(data) : []
}

export const getUserLogLS = () => {
  const data = localStorage.getItem('loggedIn');
  return data ? JSON.parse(data) : false
}


export const getTempSearchNumLS = () => {
  const data = localStorage.getItem('tempSearchNum');
  return data ? JSON.parse(data) : []
}

export const getTeachersLS = () => {
  const data = localStorage.getItem('teachers');
  return data ? JSON.parse(data) : []
}

export const getItemStrLS = (key:string) => {
  const data = localStorage.getItem(key);
  return data ? data : ''
}

export const setItemStrLS = (key:string, data:string) => {
  localStorage.setItem(key,data)
}

export const getItemLS = (key:string) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : []
}

export const setItemLS = (key:string, data:any) => {
  const jsonR = JSON.stringify(data)
  localStorage.setItem(key,jsonR)
}

export const getItemObjLS = (key:string) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : {}
}

export const setItemObjLS = (key:string, data:any) => {
  const jsonR = JSON.stringify(data)
  localStorage.setItem(key,jsonR)
}
