import { FC, useState } from "react"
import NavBar from "../../components/NavBar"
import mainLogo from './../../img/logo_main.png';
import DatePicker from "react-datepicker";
import ListOnly from "../../components/ListOnly";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { resSub } from "../../redux/slices/sheetsSlice/sheetsSlice";


export const AddSubscrip: FC = () => {



  let navigate = useNavigate();

  const [startDate, setStartDate] = useState(new Date());

  const sub = useAppSelector((state) => state.sheets.sub)
  const dispatch = useAppDispatch()

  const token = useAppSelector((state) => state.usersR.token)

  const resetStudents = () => {
    dispatch(resSub())
  }

  const onStudents = () => {
    navigate('/teacher', {state: {url: `${process.env.REACT_APP_DOM}/api/student/`, tagSearch: 'subscrip', title: 'Ученики'}} );
  }

  const onSend = () => {
    // console.log('sub')
    const inputSubNum = Number((document.querySelector('#inputSubNum') as HTMLInputElement).value)
    const inputPrice =  Number((document.querySelector('#inputPrice') as HTMLInputElement).value)
    const inputBalance = Number((document.querySelector('#inputBalance') as HTMLInputElement).value)
    // console.log(inputSubNum, inputPrice, inputBalance)

    if ((inputPrice == 1500 && inputBalance > 5) || ((inputPrice == 3000 || inputPrice == 2500) && inputBalance > 10) ) {
      alert('Ошибка: Значения некорректны. Большое кол-во занятий для указанной стоимости')
    } else {
  
    if (inputPrice < 5001 && inputPrice >= 1 && inputBalance >= 1 && inputBalance <= 10 && sub.length == 1) {

    // }


    fetch(`${process.env.REACT_APP_DOM}/api/student/${sub[0].number}/`, {
            method: 'GET',
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json; charset=UTF-8',
              'Authorization': `Token ${token}`,
            },
            credentials: 'same-origin',
          })
          .then((res) => res.json())
          .then(dataStudent => {

            // let numAbn = 1
            let kontrol = false
            if (dataStudent.subscrip) {
              // numAbn = Number(dataStudent.subscrip)
              let arr =  dataStudent.subscrip.split(',')
              if (inputPrice === 2500) {
                const abnFlam = (arr.filter((val:string) => {
                  return val[0] === 'B'
                }).map((val:string) => val.slice(1)))
                const reg = /^\d+$/
                if (abnFlam.length > 10) {
                  alert('Ошибка: Превышен лимит сгораемых абонементов')
                } else if (abnFlam.length <= 10 && reg.test(abnFlam[0])) {
                  console.log(abnFlam)
                  console.log(abnFlam[0] == false)
                  fetch(`${process.env.REACT_APP_DOM}/api/subsconst/${abnFlam[0]}/`, {
                    method: 'GET',
                    headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Token ${token}`,
                    },
                    credentials: 'same-origin',
                    })
                    .then((res) => res.json())
                    .then(dataAbn => {
                      const infoAbn = JSON.parse(dataAbn.info)
                      if (infoAbn.dateBonusParty) {
                        // if (dataAbn.balance > 1) {
                        //   alert('Ошибка: Есть действующий сгораемый абонемент')
                        // } else {
                          // kontrol = true
                          console.log('вечеринка и баланс меньше')

                          fetch(`${process.env.REACT_APP_DOM}/api/subsconst/${inputSubNum}/`, {
                            method: 'GET',
                            headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Authorization': `Token ${token}`,
                            },
                            credentials: 'same-origin',
                            })
                            .then((res) => res.json())
                            .then(dataAbn => {
                      
                      
                              if (dataAbn.id_users == 1) {
                                const infoStu = JSON.parse(sub[0].info)

                                dataAbn.id_users = sub[0].number
                                dataAbn.status = true
                                dataAbn.info = JSON.stringify({info: infoStu.info})
                                dataAbn.price = inputPrice
                                dataAbn.quantity = inputBalance
                                dataAbn.balance = inputBalance
                                dataAbn.pub_date = startDate

                                if(inputPrice === 2500) {
                                  dataAbn.item = `B${inputSubNum}`
                                }
                              // }
                      
                              fetch(`${process.env.REACT_APP_DOM}/api/subsconst/${inputSubNum}/`, {
                                method: 'PATCH',
                                headers: {
                                  accept: 'application/json',
                                  'Content-Type': 'application/json; charset=UTF-8',
                                  'Authorization': `Token ${token}`,
                                },
                                credentials: 'same-origin',
                                body: JSON.stringify(dataAbn)
                              })
                              .then((res) => res.json())
                              .then(dataSub => {
                                // console.log(Number(dataSub.id_users))
                                const userId = Number(dataSub.id_users)
                                
                                fetch(`${process.env.REACT_APP_DOM}/api/student/${userId}/`, {
                                  method: 'GET',
                                  headers: {
                                    accept: 'application/json',
                                    'Content-Type': 'application/json; charset=UTF-8',
                                    'Authorization': `Token ${token}`,
                                  },
                                  credentials: 'same-origin',
                                })
                                .then((res) => res.json())
                                .then(dataStudent => {
                                  // console.log(dataStudent)
                                  // console.log(dataStudent.subscrip)

                                  let arr = []
                                  if(dataStudent.subscrip) {
                                    arr =  dataStudent.subscrip.split(',')
                                  }

                                  arr.push(dataSub.item)

                                  dataStudent.subscrip = arr.join (',')
                      
                                  fetch(`${process.env.REACT_APP_DOM}/api/student/${userId}/`, {
                                    method: 'PATCH',
                                    headers: {
                                      accept: 'application/json',
                                      'Content-Type': 'application/json; charset=UTF-8',
                                      'Authorization': `Token ${token}`,
                                    },
                                    credentials: 'same-origin',
                                    body: JSON.stringify(dataStudent)
                                  })
                                  .then((res) => res.json())
                                  .then(dataStudent2 => {
                                    alert('Ок: Абонемент добавлен')
                                    navigate('/list');
                                  })
                                })
                      
                      
                              })
                              .catch(e => {
                                console.log('sub', e)
                              })
                            
                            
                              }
                              else {
                                console.log('занят')
                                alert('Ошибка: Абонемент занят')
                              }
                      
                            })
                      
                            .catch(e => {
                              console.log('sub', e)
                              alert('Ошибка')
                            })



                        // }
                      } else {
                        // if (dataAbn.balance > 1) {
                        //   alert('Ошибка: Есть действующий сгораемый абонемент')
                        // } else {
                          // kontrol = true
                          fetch(`${process.env.REACT_APP_DOM}/api/subsconst/${inputSubNum}/`, {
                            method: 'GET',
                            headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Authorization': `Token ${token}`,
                            },
                            credentials: 'same-origin',
                            })
                            .then((res) => res.json())
                            .then(dataAbn => {
              
              
                      if (dataAbn.id_users == 1) {
                        const infoStu = JSON.parse(sub[0].info)

                        dataAbn.id_users = sub[0].number
                        dataAbn.status = true
                        dataAbn.info = JSON.stringify({info: infoStu.info})
                        dataAbn.price = inputPrice
                        dataAbn.quantity = inputBalance
                        dataAbn.balance = inputBalance
                        dataAbn.pub_date = startDate

                        if(inputPrice === 2500) {
                          dataAbn.item = `B${inputSubNum}`
                        }
                      // }
              
                      fetch(`${process.env.REACT_APP_DOM}/api/subsconst/${inputSubNum}/`, {
                        method: 'PATCH',
                        headers: {
                          accept: 'application/json',
                          'Content-Type': 'application/json; charset=UTF-8',
                          'Authorization': `Token ${token}`,
                        },
                        credentials: 'same-origin',
                        body: JSON.stringify(dataAbn)
                      })
                      .then((res) => res.json())
                      .then(dataSub => {
                        // console.log(Number(dataSub.id_users))
                        const userId = Number(dataSub.id_users)
                        
                        fetch(`${process.env.REACT_APP_DOM}/api/student/${userId}/`, {
                          method: 'GET',
                          headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Authorization': `Token ${token}`,
                          },
                          credentials: 'same-origin',
                        })
                        .then((res) => res.json())
                        .then(dataStudent => {
                          // console.log(dataStudent)
                          // console.log(dataStudent.subscrip)

                          let arr =  dataStudent.subscrip.split(',')

                          arr.push(dataSub.item)

                          dataStudent.subscrip = arr.join (',')
              
                          fetch(`${process.env.REACT_APP_DOM}/api/student/${userId}/`, {
                            method: 'PATCH',
                            headers: {
                              accept: 'application/json',
                              'Content-Type': 'application/json; charset=UTF-8',
                              'Authorization': `Token ${token}`,
                            },
                            credentials: 'same-origin',
                            body: JSON.stringify(dataStudent)
                          })
                          .then((res) => res.json())
                          .then(dataStudent2 => {
                            alert('Ок: Абонемент добавлен')
                            navigate('/list');
                          })
                        })
              
              
                      })
                      .catch(e => {
                        console.log('sub', e)
                      })
                    
                    
                      }
                      else {
                        console.log('занят')
                        alert('Ошибка: Абонемент занят')
                      }
              
                    })
              
                    .catch(e => {
                      console.log('sub', e)
                      alert('Ошибка')
                    })


                          
                        // }
                      }
                    })
                    .catch(e => {
                      console.log('sub', e)
                      alert('Ошибка чтения базы')
                    })
                } else {
                  console.log(abnFlam)
                  kontrol = true
                }

              } else {
                kontrol = true
              }
            } else {
              kontrol = true
            }
            console.log(kontrol, 'проверка')

            if(kontrol === true) {
              fetch(`${process.env.REACT_APP_DOM}/api/subsconst/${inputSubNum}/`, {
                    method: 'GET',
                    headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Token ${token}`,
                    },
                    credentials: 'same-origin',
                    })
                    .then((res) => res.json())
                    .then(dataAbn => {
              
              
                      if (dataAbn.id_users == 1) {
                        const infoStu = JSON.parse(sub[0].info)

                        dataAbn.id_users = sub[0].number
                        dataAbn.status = true
                        dataAbn.info = JSON.stringify({info: infoStu.info})
                        dataAbn.price = inputPrice
                        dataAbn.quantity = inputBalance
                        dataAbn.balance = inputBalance
                        dataAbn.pub_date = startDate

                        if(inputPrice === 2500) {
                          dataAbn.item = `B${inputSubNum}`
                        }
                      // }
              
                      fetch(`${process.env.REACT_APP_DOM}/api/subsconst/${inputSubNum}/`, {
                        method: 'PATCH',
                        headers: {
                          accept: 'application/json',
                          'Content-Type': 'application/json; charset=UTF-8',
                          'Authorization': `Token ${token}`,
                        },
                        credentials: 'same-origin',
                        body: JSON.stringify(dataAbn)
                      })
                      .then((res) => res.json())
                      .then(dataSub => {
                        // console.log(Number(dataSub.id_users))
                        const userId = Number(dataSub.id_users)
                        
                        fetch(`${process.env.REACT_APP_DOM}/api/student/${userId}/`, {
                          method: 'GET',
                          headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Authorization': `Token ${token}`,
                          },
                          credentials: 'same-origin',
                        })
                        .then((res) => res.json())
                        .then(dataStudent => {
                          // console.log(dataStudent)
                          // console.log(dataStudent.subscrip)

                          let arr = []
                          if(dataStudent.subscrip) {
                            arr =  dataStudent.subscrip.split(',')
                          }

                          arr.push(dataSub.item)

                          dataStudent.subscrip = arr.join (',')
              
                          fetch(`${process.env.REACT_APP_DOM}/api/student/${userId}/`, {
                            method: 'PATCH',
                            headers: {
                              accept: 'application/json',
                              'Content-Type': 'application/json; charset=UTF-8',
                              'Authorization': `Token ${token}`,
                            },
                            credentials: 'same-origin',
                            body: JSON.stringify(dataStudent)
                          })
                          .then((res) => res.json())
                          .then(dataStudent2 => {
                            alert('Ок: Абонемент добавлен')
                            navigate('/list');
                          })
                        })
              
              
                      })
                      .catch(e => {
                        console.log('sub', e)
                      })
                    
                    
                      }
                      else {
                        console.log('занят')
                        alert('Ошибка: Абонемент занят')
                      }
              
                    })
              
                    .catch(e => {
                      console.log('sub', e)
                      alert('Ошибка')
                    })
            }



        })


  

  
    }
    else {
      alert('Ошибка: Значения некорректны')
    }

  }
  
  }

  return (
    <>
      <div className="List">
        <div className='lat-form'>
          <div className='lat-form__container lat-form__container_shadow'>
            <NavBar />
            <div className="lat-form__header header">
              <div className="header__left">

              </div>
              <div className="header__center">
                <img className="logo" src={mainLogo} alt='логотип'></img>
              </div>
              <div className="header__right">

              </div>
            </div>
            <div className="lat-form__sheets sheets">
              <div className="sheets__title">
                {`Новые абонементы`}
              </div>
              <div className="sheets__item">
                <div className="sheets__item-text">
                  Дата
                </div>
                <div className="sheets__item-value">
                  <DatePicker selected={startDate} onChange={(date) => setStartDate(date as Date)} />
                </div>
              </div>
              <div className="sheets__item">
                <div className="sheets__item-text">
                  Номер абонемента
                </div>
                <input id='inputSubNum' />
              </div>
              <div className="sheets__item">
                <div className="sheets__item-text">
                  Цена
                </div>
                <input id='inputPrice' />
              </div>
              <div className="sheets__item">
                <div className="sheets__item-text">
                  Количество занятий
                </div>
                <input id='inputBalance' />
              </div>
              <div className="sheets__item">
                <div className="sheets__item-text">
                  Ученик
                </div>
                <button onClick={resetStudents}>
                  Очистить всех
                </button>
                <button onClick={onStudents} className={'sheets__btn'}>
                  Выбрать
                </button>
                  {/* <NavLink className={'sheets__btn'} to='/teacher' state={{url: "http://localhost:8000/api/direction/"}}>Добавить/обновить</NavLink> */}
              </div>
              <div className='sheets__list-stu'>
                <ListOnly
                students={sub}
                />
              </div>

              <div className="sheets__send">
                <button onClick={onSend}>
                  Отправить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
