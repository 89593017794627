import React, { FC } from 'react';
import { IUserJSON } from '../types/Interface';
import { IStu, IStudent } from '../types/Student';
import './RowOnly.scss';

interface RowOnlyProps {
	student?: any;
	// student?: IUserJSON | null;
	nameRow?: string;
	number?: string | number;
	tagSearch?: string;
}

const RowOnly: FC<RowOnlyProps> = ({
	student, nameRow, number, tagSearch
}) => {
	let nameTwo:any
	let nameOne:any

	switch(tagSearch){
		case 'direction':
			nameTwo = student.name;
			nameOne = student.number;
			break
		default:
      if(student?.info) {
        const infoStu = JSON.parse(student?.info)
        nameTwo = infoStu.info
      } else {
        nameTwo = student["fio"]
      }
      nameOne = student["number"]
	}

	// console.log('Row', name, nameTwo)
	return (
		<div className="row">
			<div className="row__container">
				<div className="row__number">
					{nameOne}
				</div>
				<div className="row__name">
					{nameTwo}
				</div>
			</div>
		</div>
	);
}

export default RowOnly;
