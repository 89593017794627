import { CSSProperties, FC, ReactNode } from "react";
import './Modal.scss'

interface ModalProps {
  onModalClose: any;
  children: ReactNode;
  title: any;
  isOpened: any;
  style: CSSProperties | undefined;
}

const Modal:FC<ModalProps> = (props) => {
  return(<>
  <div className={`modal__wrapper ${props.isOpened ? 'open' : 'close'}`} style={{...props.style}}>
    <div className="modal__body">
      <div className="modal__close" onClick={props.onModalClose}>Х</div>
      <div className="modal__text">
        {props.title}
      </div>
      {props.children}
    </div>
  </div>

  </>)
}

export default Modal;
