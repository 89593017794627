import React, { FC } from 'react';
import mainLogo from './../../img/logo_main.png';
import './AddStudent.scss';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { resTempStudent, setAddStudents } from '../../redux/slices/sheetsSlice/sheetsSlice';



interface AddStudentProps {
  title?: string;
  varTeacher?: any;
}


const AddStudent: FC<AddStudentProps> = ({
  title = "Добавление студентов"
}) => {

  const dispatch = useAppDispatch()

  const [valueInput, setValueInput] = React.useState('');


  let navigate = useNavigate();


  function handleChange(event: any) {
    setValueInput(event.target.value);
  }


  const onInvites = (event: any) => {
    event.preventDefault();
    console.log(valueInput)
    dispatch(setAddStudents(valueInput));
    setValueInput('')
    navigate("/list");
  };

  const onBakc = () => {
    navigate("/list");
  };

  const onDel = () => {
    dispatch(resTempStudent());
    navigate("/list");
  };

  return (
    <div className="Students">
      <div className="lat-form">
        <div className="lat-form__container lat-form__container_shadow">
          <img className="logo" src={mainLogo} alt="логотип"></img>
          <div>{title}</div>
          <form onSubmit={onInvites}>
            <label>
              ФИО:
              <input type="text" value={valueInput} onChange={handleChange} />
            </label>
            <input type="submit" value="Добавить" />
          </form>
          <div className="lat-form__foo-btn">
            <button onClick={() => onDel()}>Удалить временных</button>
            <button onClick={() => onBakc()}>Назад</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddStudent;
