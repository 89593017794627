import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IUserGet } from '../../../types/Interface';
import { getItemLS, getItemObjLS, getItemStrLS, setItemStrLS, getTeachersLS } from '../../../utils/getUserLS';



const initialState = {
  subInfo: [],
  statementInfo: {},
  lessonsInfo: [],
  render: 0,
  subList: [],
  statementList: [],
}

export const noticeInfoSlice = createSlice({
  name: 'noticeInfo',
  initialState,
  reducers: {
		getList: (state) => {

		},
		setSubInfo: (state, action: PayloadAction<any>) => {
      state.subInfo = action.payload
		},
		setStatementInfo: (state, action: PayloadAction<any>) => {
      state.statementInfo = action.payload
		},
		setLessonsInfo: (state, action: PayloadAction<any>) => {
      state.lessonsInfo = action.payload
		},
    setRender: (state, action: PayloadAction<number>) => {
      state.render = action.payload
		},
    setSubList: (state, action: PayloadAction<any>) => {
      state.subList = action.payload
		},
    setStatementList: (state, action: PayloadAction<any>) => {
      state.statementList = action.payload
		},
  },
})

export const { 
  getList,
  setSubInfo,
  setStatementInfo,
  setLessonsInfo,
  setRender,
  setSubList,
  setStatementList,
} = noticeInfoSlice.actions

export default noticeInfoSlice.reducer
