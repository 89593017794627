import { CSSProperties, FC, InputHTMLAttributes, ReactNode, forwardRef } from "react";
import './Field.scss'


export interface IFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string
  error?: string
}

const Field = forwardRef<HTMLInputElement, IFieldProps>(({
  placeholder, error, className, type = 'text', style, ...rest}, ref) => {
    return (
      <div className={ className } style={{display: 'flex', flexDirection: 'column', alignItems: 'center', ...style}}>
        <label>
          <span >
            {}
            </span>
          <input 
            ref={ref}
            type={type}
            placeholder={placeholder}
            {...rest}
          />
        </label>
        <div style={{minHeight: '21px'}}>
          {error && <div style={{color: 'red'}}>{error}</div>}
        </div>
      </div>
    )
  }
)

export default Field;



