import React, { FC } from 'react';
import { IUserGet, IUserJSON } from '../types/Interface';
import { IStu } from '../types/Student';
import Row from './Row';
import RowOnly from './RowOnly';

interface ListOnlyProps {
	students?: any;
	// students?: IUserGet[] | null;
	// searchValue: string;
	// onChangeSearchValue: (e: React.FormEvent<HTMLInputElement>) => void;
	// onclickInvite?: any;
	// invites:any;
	isLoading?: boolean;
	tagSearch?: string;
}

const ListOnly: FC<ListOnlyProps> = ({ 
	students, isLoading, tagSearch
}) => {


	const listItems = students?.map((item: any) => {
	return <RowOnly student={item} tagSearch={tagSearch} key={item["number"]} />;
	}
	);
	if (listItems) {
		if((listItems as Array<any>).length == 0) {
			isLoading = true
		}
	}
	return (
		<>
			{isLoading ? (
				<div className='Lists'>
					Добавьте...
				</div>
			) : (
			<div className='Lists'>
				{listItems}
			</div>
			)}
		</>
	)
}

export default ListOnly;
