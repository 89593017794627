import React, { createContext, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import AppRouter from './components/AppRouter';
import NavBar from './components/NavBar';


// export const ContextInvit = createContext({} as any)

function App() {
	// const [invitess, setInvites] = useState([] as number[]);
  return (
		// <ContextInvit.Provider value={{
		// 	invitess,
		// 	setInvites
		// }}>
			<BrowserRouter>
			{/* <NavBar /> */}
				<AppRouter />
			</BrowserRouter>
		// </ContextInvit.Provider>
  );
}

export default App;
