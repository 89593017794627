import React, { useContext } from 'react';
import {Routes, Route} from 'react-router-dom'
// import { authRoutes } from '../routes';
// import { publicRoutes } from '../routes';
// import { SHOP_ROUTE } from '../utils/consts';
import Main from "../pages/Main/Main";
// import Shop from "../pages/Shop";
// import Admin from '../pages/Admin';
// import Auth from '../pages/Auth';
// import Basket from '../pages/Basket';
// import DevicePage from '../pages/DevicePage';
// import { Context } from '../index';
import List from '../pages/List/List';
import Students from '../pages/Students/Students';
import Teacher from '../pages/Teacher/Teacher';
import Users from '../pages/Users/Users';
import { RequireAuth } from './RequireAuth';
// import SystemBtn from '../pages/SystemBtn/SystemBtn';
import NavBar from './NavBar';
import AddStudent from '../pages/AddStudent/AddStudent';
import PersonalOffice from '../pages/PersonalOffice/PersonalOffice';
import { useAppSelector } from '../redux/hooks';
import { RequireAuthRole } from './RequireAuthRole';
import { AddSubscrip } from '../pages/AddSubscrip/AddSubscrip';
import SignUp from '../pages/SignUp/SignUp';
import { Notice } from '../pages/Notice/Notice';
import SignUpStu from '../pages/SignUpStu/SignUpStu';
import { AddSubscripV2 } from '../pages/AddSubscripV2/AddSubscripV2';
import { NoticeInfo } from '../pages/NoticeInfo/NoticeInfo';
import { NoticeInfoAdmin } from '../pages/NoticeInfoAdmin/NoticeInfoAdmin';
import { NoticeStudent } from '../pages/NoticeStudent/NoticeStudent';
// import { RequireAuth } from './RequireAuth';

const AppRouter = () => {
    // const {user} =useContext(Context)
    // console.log(user)

    const role = useAppSelector((state) => state.usersR.user.role)

    return (
        <Routes>
            {/* <Route path="/" element={authRoutes[0].Component}  > </Route> */}
            {/* <Route path="/" element={authRoutes.Component}></Route> */}
            <Route path="/" element={<Main />} />

            <Route path="/sign-up" element={
                <RequireAuthRole role={role}>
                    <SignUp />
                </RequireAuthRole>
            } />

            <Route path="/sign-up-stu" element={

                    <SignUpStu />

            } />

            {/* <Route path="/sign-up" element={<SignUp />} /> */}
            {/* <Route path="/list" element={<List />} />
            <Route path="/students" element={<Students />} />
            <Route path="/teacher" element={<Teacher />} />
            <Route path="/users" element={<Users />} /> */}
            <Route path="/add-students" element={
                <RequireAuthRole role={role}>
                    <AddStudent />
                </RequireAuthRole>
            } />
            <Route path="/teacher" element={
                <RequireAuthRole role={role}>
                    <Teacher />
                </RequireAuthRole>
            } />
            <Route path="/list" element={
                <RequireAuthRole role={role}>
                    <List />
                </RequireAuthRole>
            } />
            <Route path="/students" element={
                <RequireAuthRole role={role}>
                    <Students />
                </RequireAuthRole>
            } />
            <Route path="/users" element={
                <RequireAuthRole role={role}>
                    <Users />
                </RequireAuthRole>
            } />
            {/* <Route path="/systems" element={
                <RequireAuthRole role={role}>
                    <SystemBtn />
                </RequireAuthRole>
            } /> */}
            <Route path="/office" element={
                <RequireAuth>
                    <PersonalOffice />
                </RequireAuth>
            } />
            <Route path="/subscrip" element={
                <RequireAuthRole role={role}>
                    <AddSubscrip />
                </RequireAuthRole>
            } />
            <Route path="/subscripV2" element={
                <RequireAuthRole role={role}>
                    <AddSubscripV2 />
                </RequireAuthRole>
            } />
            <Route path="/notice-info" element={
                <RequireAuthRole role={role}>
                    <NoticeInfo />
                </RequireAuthRole>
            } />
            <Route path="/notice-info-admin" element={
                <RequireAuthRole role={role}>
                    <NoticeInfoAdmin />
                </RequireAuthRole>
            } />
            <Route path="/notice-student" element={
                <RequireAuthRole role={role}>
                    <NoticeStudent />
                </RequireAuthRole>
            } />
            <Route path="/notice" element={
                <RequireAuthRole role={role}>
                    <Notice />
                </RequireAuthRole>
            } />
            {/* <Route path="registration" element={<Auth />} />
            <Route path="device/:id" element={<DevicePage />} />
            <Route path="/*" element={<Shop />} /> */}



            {/* {isAuth && authRoutes.map(({path, Component}) =>
            {
                <Route key={path} path={path} element={Component}> </Route>
                // console.log(path)
                // console.log(Component)    
            }
                
            )}
            {publicRoutes.map(({path, Component}) => {
                <Route key={path} path={path} element={Component}> </Route>
                //  console.log(path)
                //  console.log(Component)
            }

            )} */}
            {/* <Rediret to={SHOP_ROUTE} /> */}
        </Routes>
    );
};


export default AppRouter;
