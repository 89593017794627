import React, { FC } from 'react';
import { IUserGet, IUserJSON } from '../types/Interface';
import { IStu } from '../types/Student';
import Row from './Row';
import RowOnly from './RowOnly';
import RowOnlyStu from './RowOnlyStu/RowOnlyStu';

interface ListOnlyStuProps {
  students?: any;
  // students?: IUserGet[] | null;
  // searchValue: string;
  // onChangeSearchValue: (e: React.FormEvent<HTMLInputElement>) => void;
  // onclickInvite?: any;
  // invites:any;
  isLoading?: boolean;
  tagSearch?: string;
}

const ListOnlyStu: FC<ListOnlyStuProps> = ({ 
  students, isLoading, tagSearch
}) => {


  const listItems = students?.map((item: any, index: any) => {
  return <RowOnlyStu student={item} tagSearch={tagSearch} key={item["number"]} index={index} />;
  }
  );
  if (listItems) {
    if((listItems as Array<any>).length == 0) {
      isLoading = true
    }
  }
  return (
    <>
      {isLoading ? (
        <div className='Lists'>
          Добавьте...
        </div>
      ) : (

          <div className='Lists-students'>
            <div className='Lists-students__name'>
              {listItems}
            </div>
          </div>

      )}
    </>
  )
}

export default ListOnlyStu;
