import { configureStore } from '@reduxjs/toolkit'
import sheetsSlice from './slices/sheetsSlice/sheetsSlice'
import usersSlice from './slices/userSlice'
import personalOffice from './slices/personalOfficeSlice/personalOfficeSlice'
import noticeSlice from './slices/noticeSlice/noticeSlice'
import noticeInfoSlice from './slices/noticeInfoSlice/noticeInfoSlice'
import noticeInfoAdminSlice from './slices/noticeInfoAdminSlice/noticeInfoAdminSlice'
import noticeStudentSlice from './slices/noticeStudentSlice/noticeStudentSlice'

export const store = configureStore({
  reducer: {
		usersR: usersSlice,
		sheets: sheetsSlice,
    personalOffice: personalOffice,
    notice: noticeSlice,
    noticeInfo: noticeInfoSlice,
    noticeInfoAdmin: noticeInfoAdminSlice,
    noticeStudent: noticeStudentSlice,
	},
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
