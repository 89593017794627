import React, { FC } from 'react';
import { IUserJSON } from '../types/Interface';
import { IStu, IStudent } from '../types/Student';
import './Row.scss';

interface RowProps {
	// student?: IUserJSON | null;
	student?: any;
	onclickInvite?: any;
	isInvited?: boolean;
}

const Row: FC<RowProps> = ({
	student,
	onclickInvite,
	isInvited
}) => {
	let nameTwo:any
	let nameOne:any
	if (student?.fio) {
		nameTwo = student["fio"]
		nameOne = student["number"]
	}
	if (student?.name) {
		nameTwo = student.name
		nameOne = student.number
	}
  if (student?.info) {
    const infoStu = JSON.parse(student?.info)
		nameTwo = infoStu.info
		nameOne = student["number"]
	}
	// console.log('Row', name, nameTwo)
	return (
		<div className="row">
			<div className="row__container">
				<div className="row__number">
					{nameOne}
				</div>
				<div className="row__name">
					{nameTwo}
				</div>
				<div className="row__check">
					<img className="action" onClick={() => onclickInvite(nameOne)} src={`/assets/${isInvited ? 'minus' : 'plus'}.svg`} alt="Action" />
					{/* <input type="checkbox" name="rowCheck" id={nameOne} /> */}
				</div>
			</div>
		</div>
	);
}

export default Row;
