import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IUserGet } from '../../../types/Interface';
import { getItemLS, getItemObjLS, getItemStrLS, setItemStrLS, getTeachersLS } from '../../../utils/getUserLS';



const initialState = {
  subInfo: [],
  statementInfo: {},
  lessonsInfo: [],
  render: 0,
  subList: [],
  statementList: [],
  statementList2: [],
  statementList3: [],
  infoUserList: [],
  dataStatCount: {} as any,
  dataStatSum: {} as any,
  dataTeachers: {} as any,
}

export const noticeInfoAdminSlice = createSlice({
  name: 'noticeInfoAdmin',
  initialState,
  reducers: {
		getList: (state) => {

		},
		setSubInfo: (state, action: PayloadAction<any>) => {
      state.subInfo = action.payload
		},
		setStatementInfo: (state, action: PayloadAction<any>) => {
      state.statementInfo = action.payload
		},
		setLessonsInfo: (state, action: PayloadAction<any>) => {
      state.lessonsInfo = action.payload
		},
    setRender: (state, action: PayloadAction<number>) => {
      state.render = action.payload
		},
    setSubList: (state, action: PayloadAction<any>) => {
      state.subList = action.payload
		},
    setStatementList: (state, action: PayloadAction<any>) => {
      state.statementList = action.payload
		},
    setStatementList2: (state, action: PayloadAction<any>) => {
      state.statementList2 = action.payload
		},
    setStatementList3: (state, action: PayloadAction<any>) => {
      state.statementList3 = action.payload
		},
    setinfoUserList: (state, action: PayloadAction<any>) => {
      state.infoUserList = action.payload
		},
    setDataStatCount: (state, action: PayloadAction<any>) => {
      state.dataStatCount = action.payload
		},
    setDataStatSum: (state, action: PayloadAction<any>) => {
      state.dataStatSum = action.payload
		},
    setDataTeachers: (state, action: PayloadAction<any>) => {
      state.dataTeachers = action.payload
		},
  },
})

export const { 
  getList,
  setSubInfo,
  setStatementInfo,
  setLessonsInfo,
  setRender,
  setSubList,
  setStatementList,
  setinfoUserList,
  setDataStatCount,
  setDataStatSum,
  setDataTeachers,
  setStatementList2,
  setStatementList3,
} = noticeInfoAdminSlice.actions

export default noticeInfoAdminSlice.reducer
