import axios from 'axios'
import { LATINA_BASE_URL } from './consts'
import { getAccessToken } from '../services/auth/auth.helper'
import { instance } from './api.interceptor'

const accessToken = getAccessToken()

export const LatinaClient = axios.create({
  baseURL: LATINA_BASE_URL,
  withCredentials: true,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

// export const LatinaClientToken = axios.create({
//   baseURL: LATINA_BASE_URL,
//   withCredentials: true,
//   headers: {
//     accept: 'application/json',
//     'Content-Type': 'application/json',
//     Authorization: `Bearer ${accessToken}`
//   },
// })

export const LatinaClientToken = instance
