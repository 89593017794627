import React, {FC, useContext} from 'react';
import { Navigate, RouteProps, useLocation } from 'react-router-dom';
import Main from '../pages/Main/Main';
import { useAppSelector } from '../redux/hooks';


const RequireAuth = ({children}) => {

	const loggedIn = useAppSelector((state) => state.usersR.loggedIn)
    const location = useLocation();

    if (!loggedIn) {
			return <Navigate to="/" state={{ from: location }} /> ;
    }

    return children;

}

export {RequireAuth};


// const RequireAuth = ({children}:{ children: JSX.Element }) => {

// 	const loggedIn = useAppSelector((state) => state.usersR.loggedIn)
//     const location = useLocation();

//     if (!loggedIn) {
// 			// @ts-ignore
// 			return (<Navigate to="/" state={{ from: location }} />) ;
//     }

//     return children;

// }

// export default RequireAuth;

// https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx
// https://adarshaacharya.com.np/blog/role-based-auth-with-react-router-v6
