import React, { FC, useContext, useEffect, useState } from 'react';
import mainLogo from './../../img/logo_main.png';
import './Students.scss';
import Lists from '../../components/Lists';
import { IStu } from '../../types/Student';
// import { Context } from '../..';
// import { ContextInvit } from '../../App';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setInvitesReset } from '../../redux/slices/userSlice';


interface MainProps {
	title?: string;
}


const Students: FC<MainProps> = ({
	title = "Students"
}) => {
	const titleM = title;

	// const [userss, setUsers] = React.useState([] as IStu[])
	// const [invitess, setInvites] = React.useState([] as number[])
	const [searchValue, setSearchValue] = React.useState('')

	// const { users, invites } = useContext(Context);
  // const { invitess, setInvites } = useContext(ContextInvit);

	// const isMounted = React.useRef(false)
	const isMountedR = React.useRef(false)



	// const usersR = useAppSelector((state) => state.usersR.usersRed)
	const invitesRed = useAppSelector((state) => state.usersR.invitesRed)
	const invitesMasRed = useAppSelector((state) => state.usersR.invitesMasRed)
  const dispatch = useAppDispatch()

	// console.log('userRed',usersR)
	// console.log('invitesRed',invitesRed)
	
	// useEffect(()=> {
	// 	if (isMounted.current) {
	// 		const json = JSON.stringify(invites.invites)
	// 		console.log('json',json)
	// 		localStorage.setItem('invites',json)
			
	// 	}
	// 	const data = localStorage.getItem('invites')
	// 	data ? invites.setInvites(JSON.parse(data)) : invites.setInvites([])
	// 	isMounted.current = true
	// }, [invites])

	useEffect(()=> {
		if (isMountedR.current) {
			const jsonR = JSON.stringify(invitesRed)
			localStorage.setItem('invitesR',jsonR)
			
		}
		isMountedR.current = true
	}, [invitesRed])


	// useEffect(() => {
	// 	setUsers(students.Ученики as IStu[])
	// })

	const onChangeSearchValue = (event: any) => {
		setSearchValue(event.target.value);
	}

	const onclickInvite = (id:any) => {
		// id = Number(id);
		// if (invites.includes(id)) {
		// 	setInvites(prev => prev.filter(_id => _id != id))
		// } else {
		// 	setInvites((prev) => [...prev, id])
		// }
	}


	// console.log(invites.invites)

	const onClickClearInvit = () => {
		// setInvites([])
		// invites.setInvites([])
		// console.log(invites.invites)
		// const json = JSON.stringify(invites.invites)
		// localStorage.setItem('invites',json)
		dispatch(setInvitesReset())
	}

	// console.log(users.users)
	return (
		<div className="Students">
			<div className='lat-form'>
				<div className='lat-form__container lat-form__container_shadow'>
					<img className="logo" src={mainLogo} alt='логотип'></img>
					<div>
						{titleM}
					</div>
					<Lists
						onChangeSearchValue={onChangeSearchValue}
						searchValue={searchValue}
						invites={invitesMasRed}
						onclickInvite={onclickInvite}
						students={invitesRed} />
					<div>
						<button onClick={()=> onClickClearInvit()}>Очистить</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Students;
