// import { getContentType } from "@/api/api.helper"
// import { axiosClassic, instance } from "@/api/api.interceptor"
// import { IAuthResponse, IEmailPassword } from "@/store/user/user.interface"
import axios from "axios"
import Cookies from "js-cookie"
import { saveToStorage } from "./auth.helper"

import { axiosClassic } from "../../API/api.interceptor"
import { REFRESH_TOKEN } from "../../constants/token.constants"

export interface ITokens {
  accessToken: string
  refreshToken: string
}

export interface IEmailPassword {
  login: string
  password: string
  email?: string
  name?: string
  phone?: string
  returnPassword?: string
}
export interface IAuthResponse extends ITokens {
  user: IUser
}
export interface IUser {
  id: number
  email: string
  name: string
  avatarPath: string
  phone: string
  isAdmin: boolean
  login: string,
  roles: Array<string>
}

export interface IFullUser extends IUser {
  favorites: string
  orders: string
}

export const AuthService = {
  async main(type: 'login' | 'register', data: IEmailPassword) {
    const response = await axiosClassic<IAuthResponse>({
      url: `/auth/${type}`,
      method: 'POST',
      data
    })

    if (response.data.accessToken) saveToStorage(response.data)

    return response.data
    
  },


  async getNewToken() {
    const refreshToken = Cookies.get(REFRESH_TOKEN)

    const response = await axiosClassic.post<string, { data: IAuthResponse }>(
      '/auth/login/access-token',
      { refreshToken },
    )

    if (response.data.accessToken) saveToStorage(response.data)

    return response

  }

}
