export interface signIn {
	email:string;
	password:string;
}
export interface signInNew {
	username: string,
	password: string,
}

export interface signUp {
	email: string;
	password: string;
	username: string;
	first_name: string;
	last_name: string;
}

export interface changePasswordNew {
	new_password: string;
	re_new_password: string;
	current_password: string;
}

export interface changePassword {
	new_password: string;
	current_password: string;
}

class Api {

	_headers:any
	_url: any

  constructor (url:any, headers:any) {
    this._url = url
    this._headers = headers
  }

  checkResponse (res:any) {
    return new Promise((resolve, reject) => {
      if (res.status === 204) {
        return resolve(res)
      }
      const func = res.status < 400 ? resolve : reject
      res.json().then((data:any) => func(data))
    })
  }

  // checkFileDownloadResponse (res:any) {
  //   return new Promise((resolve, reject) => {
  //     if (res.status < 400) {
  //       return res.blob().then((blob:any) => {
  //         const url = window.URL.createObjectURL(blob);
  //         const a = document.createElement('a');
  //         a.href = url;
  //         a.download = "shopping-list";
  //         document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  //         a.click();    
  //         a.remove();  //afterwards we remove the element again 
  //       })
  //     }
  //     reject()
  //   })
  // }

  signin (data: signInNew) {
		const username = data.username;
		const password = data.password;
		// console.log(this._url)
    return fetch(
      `${this._url}/api/auth/token/login/`,
      {
        method: 'POST',
        headers: this._headers,
        body: JSON.stringify({
          username, password
        })
      }
    ).then(this.checkResponse)
  }

  signout () {
    const token = localStorage.getItem('token')
    return fetch(
      `${this._url}/api/auth/token/logout/`,
      {
        method: 'POST',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        }
      }
    ).then(this.checkResponse)
  }

  signup (data: signUp) {
		const {email, password, username, first_name, last_name} = data
    return fetch(
      `${this._url}/api/users/`,
      {
        method: 'POST',
        headers: this._headers,
        body: JSON.stringify({
          email, password, username, first_name, last_name
        })
      }
    ).then(this.checkResponse)
  }

  getUserData () {
    const token = localStorage.getItem('token')
    return fetch(
      `${this._url}/api/users/me/`,
      {
        method: 'GET',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        }
      }
    ).then(this.checkResponse)
  }

  changePassword (data:changePassword) {
		const { current_password, new_password } = data
    const token = localStorage.getItem('token')
    return fetch(
      `${this._url}/api/users/set_password/`,
      {
        method: 'POST',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        },
        body: JSON.stringify({ current_password, new_password })
      }
    ).then(this.checkResponse)
  }


  // recipes

  // getRecipes ({
  //   page = 1,
  //   limit = 6,
  //   is_favorited = 0,
  //   is_in_shopping_cart = 0,
  //   author,
  //   tags
  // } = {}) {
  //     const token = localStorage.getItem('token')
  //     const authorization = token ? { 'authorization': `Token ${token}` } : {}
  //     const tagsString = tags ? tags.filter(tag => tag.value).map(tag => `&tags=${tag.slug}`).join('') : ''
  //     return fetch(
  //       `/api/recipes/?page=${page}&limit=${limit}${author ? `&author=${author}` : ''}${is_favorited ? `&is_favorited=${is_favorited}` : ''}${is_in_shopping_cart ? `&is_in_shopping_cart=${is_in_shopping_cart}` : ''}${tagsString}`,
  //       {
  //         method: 'GET',
  //         headers: {
  //           ...this._headers,
  //           ...authorization
  //         }
  //       }
  //     ).then(this.checkResponse)
  // }

  // getRecipe ({
  //   recipe_id
  // }) {
  //   const token = localStorage.getItem('token')
  //   const authorization = token ? { 'authorization': `Token ${token}` } : {}
  //   return fetch(
  //     `/api/recipes/${recipe_id}/`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         ...this._headers,
  //         ...authorization
  //       }
  //     }
  //   ).then(this.checkResponse)
  // }

  // createRecipe ({
  //   name = '',
  //   image,
  //   tags = [],
  //   cooking_time = 0,
  //   text = '',
  //   ingredients = []
  // }) {
  //   const token = localStorage.getItem('token')
  //   return fetch(
  //     '/api/recipes/',
  //     {
  //       method: 'POST',
  //       headers: {
  //         ...this._headers,
  //         'authorization': `Token ${token}`
  //       },
  //       body: JSON.stringify({
  //         name,
  //         image,
  //         tags,
  //         cooking_time,
  //         text,
  //         ingredients
  //       })
  //     }
  //   ).then(this.checkResponse)
  // }

  // updateRecipe ({
  //   name,
  //   recipe_id,
  //   image,
  //   tags,
  //   cooking_time,
  //   text,
  //   ingredients
  // }, wasImageUpdated) { // image was changed
  //   const token = localStorage.getItem('token')
  //   return fetch(
  //     `/api/recipes/${recipe_id}/`,
  //     {
  //       method: 'PATCH',
  //       headers: {
  //         ...this._headers,
  //         'authorization': `Token ${token}`
  //       },
  //       body: JSON.stringify({
  //         name,
  //         id: recipe_id,
  //         image: wasImageUpdated ? image : undefined,
  //         tags,
  //         cooking_time: Number(cooking_time),
  //         text,
  //         ingredients
  //       })
  //     }
  //   ).then(this.checkResponse)
  // }

  // addToFavorites ({ id }) {
  //   const token = localStorage.getItem('token')
  //   return fetch(
  //     `/api/recipes/${id}/favorite/`,
  //     {
  //       method: 'POST',
  //       headers: {
  //         ...this._headers,
  //         'authorization': `Token ${token}`
  //       }
  //     }
  //   ).then(this.checkResponse)
  // }

  // removeFromFavorites ({ id }) {
  //   const token = localStorage.getItem('token')
  //   return fetch(
  //     `/api/recipes/${id}/favorite/`,
  //     {
  //       method: 'DELETE',
  //       headers: {
  //         ...this._headers,
  //         'authorization': `Token ${token}`
  //       }
  //     }
  //   ).then(this.checkResponse)
  // }

  // getUser ({ id }) {
  //   const token = localStorage.getItem('token')
  //   return fetch(
  //     `/api/users/${id}/`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         ...this._headers,
  //         'authorization': `Token ${token}`
  //       }
  //     }
  //   ).then(this.checkResponse)
  // }

  // getUsers ({
  //   page = 1,
  //   limit = 6
  // }) {
  //   const token = localStorage.getItem('token')
  //   return fetch(
  //     `/api/users/?page=${page}&limit=${limit}`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         ...this._headers,
  //         'authorization': `Token ${token}`
  //       }
  //     }
  //   ).then(this.checkResponse)
  // }

  // subscriptions

  // getSubscriptions ({
  //   page, 
  //   limit = 6,
  //   recipes_limit = 3
  // }) {
  //   const token = localStorage.getItem('token')
  //   return fetch(
  //     `/api/users/subscriptions/?page=${page}&limit=${limit}&recipes_limit=${recipes_limit}`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         ...this._headers,
  //         'authorization': `Token ${token}`
  //       }
  //     }
  //   ).then(this.checkResponse)
  // }

  // deleteSubscriptions ({
  //   author_id
  // }) {
  //   const token = localStorage.getItem('token')
  //   return fetch(
  //     `/api/users/${author_id}/subscribe/`,
  //     {
  //       method: 'DELETE',
  //       headers: {
  //         ...this._headers,
  //         'authorization': `Token ${token}`
  //       }
  //     }
  //   ).then(this.checkResponse)
  // }

  // subscribe ({
  //   author_id
  // }) {
  //   const token = localStorage.getItem('token')
  //   return fetch(
  //     `/api/users/${author_id}/subscribe/`,
  //     {
  //       method: 'POST',
  //       headers: {
  //         ...this._headers,
  //         'authorization': `Token ${token}`
  //       }
  //     }
  //   ).then(this.checkResponse)
  // }

  // ingredients
  // getIngredients ({ name }) {
  //   const token = localStorage.getItem('token')
  //   return fetch(
  //     `/api/ingredients/?name=${name}`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         ...this._headers
  //       }
  //     }
  //   ).then(this.checkResponse)
  // }

  // tags
  // getTags () {
  //   const token = localStorage.getItem('token')
  //   return fetch(
  //     `/api/tags/`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         ...this._headers
  //       }
  //     }
  //   ).then(this.checkResponse)
  // }


  // addToOrders ({ id }) {
  //   const token = localStorage.getItem('token')
  //   return fetch(
  //     `/api/recipes/${id}/shopping_cart/`,
  //     {
  //       method: 'POST',
  //       headers: {
  //         ...this._headers,
  //         'authorization': `Token ${token}`
  //       }
  //     }
  //   ).then(this.checkResponse)
  // }

  // removeFromOrders ({ id }) {
  //   const token = localStorage.getItem('token')
  //   return fetch(
  //     `/api/recipes/${id}/shopping_cart/`,
  //     {
  //       method: 'DELETE',
  //       headers: {
  //         ...this._headers,
  //         'authorization': `Token ${token}`
  //       }
  //     }
  //   ).then(this.checkResponse)
  // }

  // deleteRecipe ({ recipe_id }) {
  //   const token = localStorage.getItem('token')
  //   return fetch(
  //     `/api/recipes/${recipe_id}/`,
  //     {
  //       method: 'DELETE',
  //       headers: {
  //         ...this._headers,
  //         'authorization': `Token ${token}`
  //       }
  //     }
  //   ).then(this.checkResponse)
  // }

  // downloadFile () {
  //   const token = localStorage.getItem('token')
  //   return fetch(
  //     `/api/recipes/download_shopping_cart/`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         ...this._headers,
  //         'authorization': `Token ${token}`
  //       }
  //     }
  //   ).then(this.checkFileDownloadResponse)
  // }
}

// export default new Api('http://localhost:8000', { 'content-type': 'application/json' })
export default new Api(`${process.env.REACT_APP_DOM}`, { 'content-type': 'application/json' })
