

import axios from "axios"
import Cookies from "js-cookie"
import { instance } from "../API/api.interceptor"
import { IFullUser, IUser } from "./auth/auth.service"

const USERS = 'users'

type TypeData = {
  email: string
  password?: string
  name?: string
  avatarPath?: string
  phone?: string
}

export const UserService = {
  async getProfile() {
    return instance<IFullUser>({
      url: `${USERS}/profile`,
      method: 'GET',
    })
  },

  async updateProfile(data: TypeData) {
    return instance<IUser>({
      url: `${USERS}/profile`,
      method: 'PUT',
      data
    })
  },

  async toggleFavorite(productId: string | number) {
    return instance<IUser>({
      url: `${USERS}/profile/favorites/${productId}`,
      method: 'PATCH',
    })
  },

}
