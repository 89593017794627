import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IUserGet } from '../../../types/Interface';
import { getItemLS, getItemObjLS, getItemStrLS, setItemStrLS, getTeachersLS } from '../../../utils/getUserLS';



const initialState = {
  list: [],
  bithday: []
}

export const noticeSlice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
		getList: (state) => {

		},
		setList: (state, action: PayloadAction<any>) => {
      state.list = action.payload
		},
		setBithday: (state, action: PayloadAction<any>) => {
      state.bithday = action.payload
		},
  },
})

export const { 
  getList,
  setList,
  setBithday
} = noticeSlice.actions

export default noticeSlice.reducer
