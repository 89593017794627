import axios from "axios";
import { errorCatch, getContentType } from "./api.helper";
import { LATINA_BASE_URL } from "./consts";
import { AuthService } from "../services/auth/auth.service";
import { getAccessToken, removeFromStorage } from "../services/auth/auth.helper";



const API_URI = LATINA_BASE_URL

const axiosOptions = {
  baseURL: API_URI,
  headers: getContentType()
}

export const axiosClassic = axios.create(axiosOptions)

export const instance = axios.create(axiosOptions)

instance.interceptors.request.use(config => {
  const accessToken = getAccessToken()

  if(config && config.headers && accessToken)
    config.headers.Authorization = `Bearer ${accessToken}`

  return config
})

instance.interceptors.response.use(config => config, async error => {
  const originalRequest = error.config

  if(
    (error?.response?.status === 401 ||
      errorCatch(error) === 'jwt expired' ||
      errorCatch(error) === 'jwt must be provided') &&
    error.config &&
    !error.config._isRetry
  ) {
    originalRequest._isRetry = true
    try{
      //get new Tokens
      await AuthService.getNewToken()
      return instance.request(originalRequest)
    } catch (error) {
      if(errorCatch(error) === 'jwt expired') {
        // delete tokens
        removeFromStorage()
      }
    }
  }

  throw error

})
