import React, {FC, useContext} from 'react';
// import {Context} from "../index";
import {NavLink} from "react-router-dom";
// import {ADMIN_ROUTE, LOGIN_ROUTE, SHOP_ROUTE, BASKET_ROUTE} from "../utils/consts";
import {useNavigate} from 'react-router-dom'
import { useAppSelector } from '../redux/hooks';
import './NavBar.scss';

const NavBar = () => {
    // const {user} = useContext(Context)
    const navigate = useNavigate()


    const loggedIn = useAppSelector((state) => state.usersR.loggedIn)
    const role = useAppSelector((state) => state.usersR.user.role)
    const username = useAppSelector((state) => state.usersR.user.username)
    const roleAdmin = role == 'ADMIN'
    const roleSuperAdmin = role == 'SUPERADMIN'
    // const roleCoach = role == 'coach'
    const roleModerator = role == 'MODERATOR'
    const roleTeacher = role == 'TEACHER'
    const roleUser = role == 'USER'
    // const logOut = () => {
    //     user.setUser({})
    //     user.setIsAuth(false)
    // }

    return (
        <div className="lat-navbar">
            <div className="lat-navbar__container">

                <div className='lat-navbar__item'>
                  <NavLink to='/'>Главная</NavLink>
                </div>
                {!loggedIn ? 
                  <>
                    <div className='lat-navbar__item'>
                      <NavLink to='/sign-up-stu'>Заявка</NavLink>
                    </div>
                  </>
                  :
                  <>
                  </>
                }

                {loggedIn && roleUser ? 
                  <>
                    <div className='lat-navbar__item'>
                      <NavLink to='/office'>Личный кабинет</NavLink>
                    </div>
                  </>
                :
                <>
                </>}
                {loggedIn && (roleAdmin || roleSuperAdmin || roleModerator || roleTeacher) ?
                  <>
                    <div className='lat-navbar__item'>
                      <NavLink to='/list'>Ведомость</NavLink>
                    </div>
                    <div className='lat-navbar__item'>
                      <NavLink to='/subscripV2'>Абонементы New</NavLink>
                    </div>
                    <div className='lat-navbar__item'>
                      <NavLink to='/notice-info'>Инфо</NavLink>
                    </div>
                    <div className='lat-navbar__item'>
                      <NavLink to='/notice'>Уведомления</NavLink>
                    </div>
                    <div className='lat-navbar__item'>
                      <NavLink to='/notice-student'>Ученики</NavLink>
                    </div>
                  </>
                  :
                  <></>
                }
                {loggedIn && (roleAdmin || roleSuperAdmin || roleModerator) ? 
                  <>
                    <div className='lat-navbar__item'>
                      <NavLink to='/sign-up'>Регистрация</NavLink>
                    </div>
                    <div className='lat-navbar__item'>
                      <NavLink to='/notice-info-admin'>Инфо_Доп</NavLink>
                    </div>
                  </>
                :
                <>
                </>}
                {loggedIn && roleSuperAdmin ?
                  <>
                    <div className='lat-navbar__item'>
                      <NavLink to='/subscrip'>Новые абонементы</NavLink>
                    </div>
                    <div className='lat-navbar__item'>
                      {/* <NavLink to='/students'>Ученики</NavLink> */}
                      <NavLink to='/systems'>Система</NavLink>
                    </div>
                  </>
                  :
                  <></>
                }
                
            </div>
        </div>

    );
};

export default NavBar;
