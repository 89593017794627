import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { IUserGet } from '../../types/Interface'
import { getItemObjLS, getItemStrLS, getUserLogLS, getUserLS, getUserMasLS, setItemStrLS } from '../../utils/getUserLS'

export interface UsersStateFull {
  user: Record<string, string>,
  usersRed: IUserGet[],
	invitesRed: IUserGet[],
	invitesMasRed: number[],
	loggedIn: boolean,
  token: string,
}

export interface UsersState {
  usersRed: IUserGet[],
}
export interface InvitesState {
	invitesRed: IUserGet[],
}

const initialState: UsersStateFull = {
  user: getItemObjLS('user'),
  usersRed: [],
	invitesRed: getUserLS(),
	invitesMasRed: getUserMasLS(),
	loggedIn: getUserLogLS(),
  token: getItemStrLS('token'),
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
		setUsersR: (state, action: PayloadAction<UsersState>) => {
			// console.log('payload',action.payload)
			// console.log('state',state.usersRed)
			state.usersRed = action.payload.usersRed
			// console.log('mas',mas)

			// state.usersRed.concat(action.payload.usersRed)
			// state.usersRed = [...mas ]
			// state.usersRed
			// Object.assign(state.usersR, action.payload);
			// console.log('state22',state.usersRed)
			// state.usersR.concat(action.payload)
		},
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload
      const jsonR = JSON.stringify(state.user)
			localStorage.setItem('user', jsonR)
    },
		setInvitesReset: (state) => {
			state.invitesRed = []
			state.invitesMasRed = []
			// console.log('state33',state.invitesRed)
			const jsonR = JSON.stringify(state.invitesRed)
			localStorage.setItem('invitesR',jsonR)
			const jsonRR = JSON.stringify(state.invitesMasRed)
			localStorage.setItem('invitesMasR',jsonRR)
		},
		setInvitesMasR: (state, action: PayloadAction<number>) => {
			// state.invitesMasRed = action.payload
			// console.log('setr',action.payload)
			if (state.invitesMasRed.includes(action.payload)) {
				state.invitesMasRed = state.invitesMasRed.filter(id => id != action.payload)
			} else {
				state.invitesMasRed = [...state.invitesMasRed, action.payload]
			}
			const jsonRR = JSON.stringify(state.invitesMasRed)
			localStorage.setItem('invitesMasR',jsonRR)
		},
		setInvitesRR: (state) => {
			const arr = state.usersRed.filter((item:any) => {
				return state.invitesMasRed.includes(item.number)
			})
			state.invitesRed = arr
			const jsonR = JSON.stringify(state.invitesRed)
			localStorage.setItem('invitesR',jsonR)
		},
		setLoggedIn: (state, action: PayloadAction<boolean>) => {
			state.loggedIn = action.payload
			const jsonR = JSON.stringify(state.loggedIn)
			localStorage.setItem('loggedIn',jsonR)
		},
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
      setItemStrLS('token', action.payload)
    },
    resLogged: (state) => {
      state.loggedIn = false
      const resUser = {
        email: '',
        id: '0',
        username: '',
        fio: '',
        nickname: '',
        role: 'guest'
      }
      state.user = {...resUser}
      const jsonR = JSON.stringify(state.user)
			localStorage.setItem('user', jsonR)
    }
  },
})

export const {
  setUser,
  setUsersR, 
  setInvitesReset, 
  setInvitesMasR, 
  setInvitesRR, 
  setLoggedIn, 
  setToken,
  resLogged,
} = usersSlice.actions

export const selectUsers = (state: RootState) => state.usersR

export default usersSlice.reducer
