import React, { FC, useContext, useEffect, useState } from "react";
import mainLogo from "./../../img/logo_main.png";
import "./Users.scss";
import Lists from "../../components/Lists";
import { IStu } from "../../types/Student";
import { IUserGet, IUserJSON } from "../../types/Interface";
// import { Context } from "../..";
// import { ContextInvit } from "../../App";

import { setUsersR, UsersState, setInvitesMasR, setInvitesRR } from '../../redux/slices/userSlice'
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";

interface UsersProps {
  title?: string;
}

const Users: FC<UsersProps> = ({ title = "Users" }) => {
  // const { users, invites } = useContext(Context);
  // const { invitess, setInvites } = useContext(ContextInvit);
	// const isMounted = React.useRef(false)
	const isMountedR = React.useRef(false)

	let navigate = useNavigate();

	const usersR = useAppSelector((state) => state.usersR.usersRed)
	const invitesRed = useAppSelector((state) => state.usersR.invitesRed)
	const invitesMasRed = useAppSelector((state) => state.usersR.invitesMasRed)
  const dispatch = useAppDispatch()



	// console.log('redux', usersR)

	
	// useEffect(()=> {
	// 	if (isMounted.current) {
	// 		const json = JSON.stringify(invites.invites)
	// 		const jsonR = JSON.stringify(invitesRed)
			// console.log('json',json)
			// console.log('jsonR',jsonR)
			// localStorage.setItem('invites',json)
			// localStorage.setItem('invitesR',jsonR)
			
		// }
		// const data = localStorage.getItem('invites')
		// const dataR = localStorage.getItem('invitesR')
		// data ? invites.setInvites(JSON.parse(data)) : invites.setInvites([])
		// dataR ? dispatch(setInvitesR({invitesRed: JSON.parse(dataR)})) : dispatch(setInvitesR({invitesRed: []}))
	// 	isMounted.current = true
	// }, [invites])
	useEffect(()=> {
		if (isMountedR.current) {
			const jsonR = JSON.stringify(invitesRed)
			// console.log('jsonR',jsonR)
			localStorage.setItem('invitesR',jsonR)
			
		}
		// const dataR = localStorage.getItem('invitesR')
		// if (dataR) {
			// dispatch(setInvitesR({invitesRed: JSON.parse(dataR)}))
		// }
		// dataR ? dispatch(setInvitesR({invitesRed: JSON.parse(dataR)})) : dispatch(setInvitesR({invitesRed: []}))
		isMountedR.current = true
	}, [invitesRed])
	
  const titleM = title;
	
  // const [userss, setUsers] = React.useState([] as IUserJSON[]);
  const [isLoading, setLoading] = React.useState(true);
  // const [invitess, setInvites] = React.useState([] as number[]);
  const [searchValue, setSearchValue] = React.useState("");
	
	// console.log('invitesss',invitess)
	// console.log('invitesssRedux',invitesMasRed)
  useEffect(() => {
    // setUsers(usersJS.Users as unknown as IUserJSON[])
    const data = fetch(`${process.env.REACT_APP_DOM}/api/users/`)
    // const data = fetch("http://localhost:8000/api/users/")
      .then((res) => res.json())
      .then((data) => {
        // console.log('data',data);
        // setUsers(data as IUserJSON[]);
        // users.setUsers(data);
				// dispatch(setUsersR({usersRed: data}))

      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const onChangeSearchValue = (event: any) => {
    setSearchValue(event.target.value);
  };

  const onclickInvite = (id: any) => {
    id = Number(id);
		dispatch(setInvitesMasR(id))
    // if (invitess.includes(id)) {
      // setInvites((prev:any) => prev.filter((_id:any) => _id != id));
			// console.log('invitess', id)
    // } else {
      // setInvites((prev:any) => [...prev, id]);
    // }
  };

  const onInvites = () => {
    // console.log(invitess);
    // for (let i = 0; i < invitess.length - 1; i++) {
    //   console.log(i);
			dispatch(setInvitesRR())
      // const arr = users.users.filter((item:any) => {
				// console.log('in', invitess.includes(item.number))
				// return invitess.includes(item.number)
			// });
			// console.log('arr', arr)
			// invites.setInvites([...arr])
			// const json = JSON.stringify(invites.invites)
			// localStorage.setItem('invites',json)
			// console.log('log',usersR)
			// console.log('log',usersR[0])
			// dispatch(setInvitesR({invitesRed: arr}))
			// const jsonR = JSON.stringify(invitesRed)
			// localStorage.setItem('invitesR',jsonR)
    // }
  };

  return (
    <div className="Students">
      <div className="lat-form">
        <div className="lat-form__container lat-form__container_shadow">
          <img className="logo" src={mainLogo} alt="логотип"></img>
          <div>{titleM}</div>
          <Lists
            isLoading={isLoading}
            onChangeSearchValue={onChangeSearchValue}
            searchValue={searchValue}
            invites={invitesMasRed}
            onclickInvite={onclickInvite}
            students={usersR}
          />
          <div>
            <button onClick={() => onInvites()}>Обновить</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
