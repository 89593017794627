import React, { createContext, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import UserStore from './store/UserStore';
import { IUserJSON } from './types/Interface';
import UsersStore from './store/UsersStore';
import InvitesStore from './store/InvitesStore';


import { store } from './redux/store'
import { Provider } from 'react-redux'


// interface IContextProps {
// 	user: any,
// 	users: any,
// 	invites: any,
// }



// export const Context = createContext({} as IContextProps)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
		<Provider store={store}>
			{/* <Context.Provider value={{
				user: new UserStore(),
				users: new UsersStore(),
				invites: new InvitesStore,
			}}> */}
			<App />
			{/* </Context.Provider> */}
		</Provider>
  //{/* </React.StrictMode> */}
);
