import { FC, useState } from "react"
import NavBar from "../../components/NavBar"
import mainLogo from './../../img/logo_main.png';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import './NoticeStudent.scss'
import LatinaController from "../../API/LatinaController";
import Modal from "../../components/Modal/Modal";
import { setInfoStudent, setRender } from "../../redux/slices/noticeStudentSlice/noticeStudentSlice";



export const NoticeStudent: FC = () => {
  const dispatch = useAppDispatch()

  const infoStudent = useAppSelector((state) => state.noticeStudent.infoStudent)

  const render = useAppSelector((state) => state.noticeStudent.render)

  const role = useAppSelector((state) => state.usersR.user.role)

  const roleAdmin = role === 'ADMIN'
  const roleSuperAdmin = role === 'SUPERADMIN'
  const roleModerator = role === 'MODERATOR'
  const roleTeacher = role === 'TEACHER'
  const roleUser = role === 'USER'
  const [modal, setModal] = useState(false);
  const [modalAction, setModalAction] = useState(0);
  const [modalText, setModalText] = useState('');


  const onSendModalNo = () => {
    // console.log('modal-No', this)
    setModal(false)
  }

  const onSendModalYes = () => {
    // console.log('modal-Yes', this)
    setModal(false)
    if(modalAction === 1) {
      // console.log('1')

    }
    if(modalAction === 2) {
      // console.log('2')

    }
    if(modalAction === 3) {
      // console.log('2')

    }
  }


  const listInfoStudent = () => {
    // console.log('listInfoStudent', infoStudent.name)
    
    return(
      <>
        <div>
          <span>Имя: </span>
          <span><b>{infoStudent.name}</b></span>
        </div>
        { roleAdmin || roleSuperAdmin || roleModerator ? (<>
          <div>
            <span>Логин: </span>
            <span><b>{infoStudent.login}</b></span>
          </div>
          <div>
            <span>Email: </span>
            <span><b>{infoStudent.email}</b></span>
          </div>
          <div>
            <span>Phone: </span>
            <span><b>{infoStudent.phone}</b></span>
          </div>
          <div>
            <span>День рождения: </span>
            <span><b>{infoStudent.birthdate}</b></span>
          </div>
          <div>
            <span>Ссылка: </span>
            <span><b><a href={`${infoStudent.link}`}>{infoStudent.link}</a></b></span>
          </div>
          <div>
            <span>Роль: </span>
            <span><b>{infoStudent?.roles[0]}</b></span>
          </div>
        </>) : (<></>)}
        <div className='info-student-item'>
          <span>Активные абонементы: </span>
          {infoStudent.student.subscriptions.length > 0 ? (
            infoStudent.student.subscriptions.map((thisElem, ind) => {
              if(!thisElem.isActive) return
              return (<span key={`activeSub-${ind}`} style={{ color: thisElem.balance > 0 ? 'green' : 'black'}}><b>
                {thisElem.numberSubscription}({thisElem.balance}){ind !== infoStudent.student.subscriptions.length - 1 ? ', ' : ''}
              </b>
              </span>)
            })
          ) : (<>
          </>)}
        </div>
        <div>
          <span>Все абонементы: </span>
          <span><b>{infoStudent.student.subscriptionsList.split(',').join(', ')}</b></span>
        </div>
      </>)
  }


  const onInfoStudent = async () => {

    const number =  (document.querySelector('#number') as HTMLInputElement).value
    const reg = /^\d+$/

    let user
    if(reg.test(number.toString())) {
      const dataSendStat = {
        id: number
      }
      user = await LatinaController.infoUser(dataSendStat)

    }
    


    // console.log('user', user)

    dispatch(setInfoStudent(user))
    dispatch(setRender(1))

  }

  const onRatingStudent = async () => {

    const number =  (document.querySelector('#number') as HTMLInputElement).value
    const reg = /^\d+$/

    let subscriptionAll
    if(reg.test(number.toString())) {
      const dataSendStat = {
        id: number
      }
      // subscriptionAll = await LatinaController.subscriptionAll(dataSendStat)
      ;({subscriptionAll} = await LatinaController.ratingStudentSub(dataSendStat))

    }
    
    let students:any = { }
    let arrSort = []

    if(subscriptionAll) {
      subscriptionAll.map((thisSub:any) => {
        if(thisSub.id > 1000) {
          if(!students[thisSub.studentId]) {
            students[thisSub.studentId] = {}
            students[thisSub.studentId].count = 0
            students[thisSub.studentId].countLesson = 0
            const info = JSON.parse(thisSub.info)
            students[thisSub.studentId].fio = info.info
          }

          if(students[thisSub.studentId] && students[thisSub.studentId].count !== undefined)
          students[thisSub.studentId].count = students[thisSub.studentId].count + 1
          students[thisSub.studentId].countLesson = students[thisSub.studentId].countLesson + thisSub.quantity - thisSub.balance

        }
      })
    }

    for (let keyStudents of Object.keys(students)) {
      arrSort.push({id: keyStudents,  count: students[keyStudents].count, fio: students[keyStudents].fio, les: students[keyStudents].countLesson})
    }

    arrSort.sort((a,b) => {
      return b.count - a.count
    })

    // console.log('subscriptionAll', subscriptionAll)
    // console.log('students', students)
    // console.log('arrSort', arrSort)



    // dispatch(setInfoStudent(user))
    // dispatch(setRender(1))

  }



  const onRender = () => {

    // console.log('infoStudent', infoStudent)

    switch(render) {
      case 0:  // if (x === 'value1')
        return <>
          <div className="sheets__item">
            <div className="sheets__item-text-info">
            </div>
          </div>
        </>
        break
    
      case 1:  // if (x === 'value2')
        return <>
          <div className="sheets__item">
            <div className="sheets__item-text-info">
              {infoStudent ? listInfoStudent() : <></>}
            </div>
          </div>
        </>
        break
    
      case 2:  // if (x === 'value2')
        return <>
          <div className="sheets__item">
            <div className="sheets__item-text-info">
            </div>
          </div>
        </>
        break
    
      case 3:  // if (x === 'value2')
        return <>
          <div className="sheets__item">
            <div className="sheets__item-text-info">
            </div>
          </div>
        </>
        break
    
      default:
        return <>
          <div className="sheets__item">
            <div className="sheets__item-text-info">
            </div>
          </div>
        </>
        break
    }
  }

  return (
    <>
      <div className="List">
        <div className='lat-form'>
          <div className='lat-form__container lat-form__container_shadow'>
            <NavBar />
            <div className="lat-form__header header">
              <div className="header__left">

              </div>
              <div className="header__center">
                <img className="logo" src={mainLogo} alt='логотип'></img>
              </div>
              <div className="header__right">

              </div>
            </div>
            <div className="lat-form__sheets sheets">
              <div className="sheets__title">
                {`Информация о пользователях`}
              </div>
              <div className="sheets__item">
                <div className="sheets__item-text-info">
                  Номер 
                  <span className='sign-up-primer'> (Только цифры)</span>
                </div>
                <input id='number' />
              </div>
                {onRender()}
              <div className="sheets__send">
                <button onClick={onInfoStudent}>
                  Инфо ученика
                </button>
                {roleSuperAdmin ? <>
                  <button onClick={onRatingStudent}>
                    Рейтинг
                  </button>
                </> : <></>}
                {(roleSuperAdmin || roleAdmin || roleModerator) 
                ? <>
                <button onClick={() => {
                  const number =  (document.querySelector('#number') as HTMLInputElement).value
                  const reg = /^\d+$/
                  if(reg.test(number.toString())) {
                    setModalText(`Вы хотите изменить данные ученика ${number}?`)
                  } else {
                    setModalText(`Вы хотите изменить данные ученика?`)
                  }
                  setModalAction(1)
                  setModal(true)
                  }}>
                  Изменить данные
                </button>

                </> 
                : <>
                </>}
              </div>
            </div>

            <Modal isOpened={modal} onModalClose={() => {setModal(false)}} title={modalText} style={{}} >
                  <div className='btn-modal'>
                    <button className='btn-modal__elem' onClick={onSendModalYes}>
                      Да
                    </button>
                    <button className='btn-modal__elem' onClick={onSendModalNo}>
                      Нет
                    </button>
                    
                  </div>
            </Modal>

          </div>
        </div>
      </div>
    </>
  )
}
