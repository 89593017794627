export function queryString(
  queryParams: Record<string, string | number | undefined>,
): string {
  return Object.entries(queryParams)
    .filter(([_, value]) => value !== undefined)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value!)}`,
    )
    .join('&');
}
