import { FC } from "react"
import NavBar from "../../components/NavBar"
import mainLogo from './../../img/logo_main.png';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setBithday, setList } from "../../redux/slices/noticeSlice/noticeSlice";
import LatinaController from "../../API/LatinaController";



export const Notice: FC = () => {
  const dispatch = useAppDispatch()
  const token = useAppSelector((state) => state.usersR.token)
  const list = useAppSelector((state) => state.notice.list)
  const bithday = useAppSelector((state) => state.notice.bithday)

  const ArrStu:Array<any> = []
  const ArrStu2:Array<any> = []

  const onSub = async() => {

    const dataSendStat = {
      id: 1
    }

    let dataSubsconstAll: Array<any> = await LatinaController.subscriptionAll(dataSendStat)

    
    let arrSubscription = dataSubsconstAll.filter((elFil) => {
      if (elFil.studentList != '1' && (elFil.isActive === true || elFil.balance > 0)) {
        return true
      }
    })
    .filter(elArr => {
      if(elArr.price === 2500 && elArr.isActive === true && elArr.id > 1150 && elArr.balance > 0) {
        return true
      }
    })
    .map((el:any) => {
      const subDate = new Date(Date.parse(el.dateBegin))
      const subDate2 = new Date(Date.parse(el.dateBegin))
      const from = new Date()
      let newDate =  new Date(subDate.setDate(subDate.getDate() + 21))
      // const to = new Date()
      // from.setDate(from.getDate() - 3)
      // to.setDate(to.getDate() + 1)
      if (from.getTime() > newDate.getTime()) {

        const infoAbn = JSON.parse(el.info)
        el.name = infoAbn.info
        // let newDate2 =  new Date(subDate.setDate(subDate.getDate() + 10))
        let newDate2 =  new Date(subDate2.setMonth(subDate2.getMonth() + 1))
        el.dataEnd = newDate2.toISOString()
        ArrStu2.push(el)
      } 

    })

    console.log('dataSubsconstAll', arrSubscription)
    console.log('ArrStu2', ArrStu2)

      dispatch(setList(ArrStu2))
      dispatch(setBithday([]))
    
    // fetch(`http://${process.env.REACT_APP_DOM}/api/subsconst/`, {
    //   method: 'GET',
    //   headers: {
    //     accept: 'application/json',
    //     'Content-Type': 'application/json; charset=UTF-8',
    //     'Authorization': `Token ${token}`,
    //   },
    //   credentials: 'same-origin',
    // })
    // .then((res) => res.json())
    // .then(dataSubsconst => {
    //   // console.log(dataStudent)
    //   ;let arr = (dataSubsconst as Array<any>).filter((elFil:any) => {
    //     if (elFil.id_users != '1' && (elFil.status === true || elFil.balance > 0)) {
    //       return true
    //     }
    //   })
    //   arr = arr.filter((elArr:any) => {
    //     if(elArr.price === 2500 && elArr.status === true && elArr.id > 1150 && elArr.balance > 0) {
    //       return true
    //     }
    //   })
    //   .map((el:any) => {
    //     const subDate = new Date(Date.parse(el.pub_date))
    //     const subDate2 = new Date(Date.parse(el.pub_date))
    //     const from = new Date()
    //     let newDate =  new Date(subDate.setDate(subDate.getDate() + 21))
    //     // const to = new Date()
    //     // from.setDate(from.getDate() - 3)
    //     // to.setDate(to.getDate() + 1)
    //     if (from.getTime() > newDate.getTime()) {

    //       const infoAbn = JSON.parse(el.info)
    //       el.name = infoAbn.info
    //       // let newDate2 =  new Date(subDate.setDate(subDate.getDate() + 10))
    //       let newDate2 =  new Date(subDate2.setMonth(subDate2.getMonth() + 1))
    //       el.dataEnd = newDate2.toISOString()
    //       ArrStu.push(el)
    //     } 

    //   })
    //   // dispatch(setList(ArrStu))
    //   // dispatch(setBithday([]))
    // })
    
  }
  
  const listItems = list?.map((item: any, index: any) => {
    return <div key={index}>
      <span>ФИО: </span>
      <span><b>{item.name}</b></span>
      <span> Абонемент: </span>
      <span><b>{item.id}</b></span>
      <span> Кол-во занятий: </span>
      <span><b>{item.balance}</b></span>
      {/* <span> Дата начала: </span>
      <span>{item.pub_date.slice(0,10)}</span> */}
      <span> Дата конца: </span>
      <span><b>{item.dataEnd.slice(0,10)}</b></span>
    </div>;
    }
  );

  const dayItems = bithday?.map((item: any, index: any) => {
    const subDate = new Date(Date.parse('2023-' + item.birthdate.slice(5)))
    const from = new Date()
    const to = new Date()
    from.setDate(from.getDate() - 3)
    to.setDate(to.getDate() + 3)
    if (from.getTime() <= subDate.getTime() && subDate.getTime() <= to.getTime()) {
      return <div key={index}>
      <span>ФИО: </span>
      <span><b>{item.name}</b></span>
      <span> Дата рождения: </span>
      <span style={{ color: 'red' }}><b>{item.birthdate.slice(5)}</b></span>
    </div>;
    }
    
    return <div key={index}>
      <span>ФИО: </span>
      <span><b>{item.name}</b></span>
      <span> Дата рождения: </span>
      <span><b>{item.birthdate.slice(5)}</b></span>
    </div>;
    }
  );


  const onDay = async() => {

    
    const dataSendStat = {
      id: 1
    }

    let dataUserAll: Array<any> = await LatinaController.userBirthday(dataSendStat)

    console.log('dataUserAll',dataUserAll)

    const userArr = dataUserAll.filter((elFil:any) => {
      if (elFil.birthdate) {

        const subDate = new Date(Date.parse('2023-' + elFil.birthdate.slice(5)))
        const from = new Date()
        const to = new Date()
        from.setDate(from.getDate() - 14)
        to.setDate(to.getDate() + 14)
        if (from.getTime() <= subDate.getTime() && subDate.getTime() <= to.getTime()) {
          return true
        }
      }
    })

    userArr.sort((a,b) => {
      const subDate = new Date(('2023-' + a.birthdate.slice(5))).getTime()
      const subDate2 = new Date(('2023-' + b.birthdate.slice(5))).getTime()
      
      return subDate > subDate2 ? 1 : -1
    })

    dispatch(setBithday(userArr))
    dispatch(setList([]))

    // fetch(`http://${process.env.REACT_APP_DOM}/api/users/`, {
    //   method: 'GET',
    //   headers: {
    //     accept: 'application/json',
    //     'Content-Type': 'application/json; charset=UTF-8',
    //     'Authorization': `Token ${token}`,
    //   },
    //   credentials: 'same-origin',
    // })
    // .then((res) => res.json())
    // .then(dataUsers => {
    //   // console.log(dataUsers)

    //   ;let arr = (dataUsers as Array<any>).filter((elFil:any) => {
    //     if (elFil.birthday) {

    //       const subDate = new Date(Date.parse('2023-' + elFil.birthday.slice(5)))
    //       const from = new Date()
    //       const to = new Date()
    //       from.setDate(from.getDate() - 14)
    //       to.setDate(to.getDate() + 14)
    //       if (from.getTime() <= subDate.getTime() && subDate.getTime() <= to.getTime()) {
    //         return true
    //       }
    //     }
    //   })
    //   arr.sort((a,b) => {
    //     const subDate = new Date(('2023-' + a.birthday.slice(5))).getTime()
    //     const subDate2 = new Date(('2023-' + b.birthday.slice(5))).getTime()
        
    //     return subDate > subDate2 ? 1 : -1
    //   })
    //   // console.log(arr)

    //   // dispatch(setBithday(arr))
    //   // dispatch(setList([]))
    // })

  }

  // const onSend = async () => {
    // const dataBody = {
    //   "Person": 
    //   {
    //     "EMailAddresses": 
    //     [
    //       ""
    //     ]
    //   },
    //   "Message": 
    //   {
    //     "MessageHeader": "Заголовок письма",
    //     "MessageAsText": "Текст сообщения строкой",
    //     "MessageAsHtml": "Текст сообщения тегами html"
    //   }
    // }
    // const fet = await fetch(`http://localhost:3000/Api/MessageSender/Send`, {
    //   method: 'POST',
    //   headers: {
    //     accept: 'application/json',
    //     'Content-Type': 'application/json',
    //     'Authorization': ``,
    //     'Access-Control-Allow-Origin': 'http://localhost:3000',
    //   },
    //   body: JSON.stringify(dataBody)
    // })
    // console.log(fet)


    // const dataF = fetch(`http://${process.env.REACT_APP_DOM}/api/users/`, {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json; charset=UTF-8',
    //     'Authorization': `Token ${token}`,
    //   },
    // })
    // .then((res) => res.json())
    // .then((data) => {
    //   console.log(data)
    // })

    // fetch(`http://${process.env.REACT_APP_DOM}/api/subsconst/1205/`, {
    //   method: 'GET',
    //   headers: {
    //     accept: 'application/json',
    //     'Content-Type': 'application/json; charset=UTF-8',
    //     'Authorization': `Token ${token}`,
    //   },
    //   credentials: 'same-origin',
    // })
    // .then((res) => res.json())
    // .then(dataAbn => {
    //   console.log(dataAbn)
    // })
    // const UsersObj = useGetUsersObj()
    // console.log(UsersObj)


  // }

  return (
    <>
      <div className="List">
        <div className='lat-form'>
          <div className='lat-form__container lat-form__container_shadow'>
            <NavBar />
            <div className="lat-form__header header">
              <div className="header__left">

              </div>
              <div className="header__center">
                <img className="logo" src={mainLogo} alt='логотип'></img>
              </div>
              <div className="header__right">

              </div>
            </div>
            <div className="lat-form__sheets sheets">
              <div className="sheets__title">
                {`Уведомления`}
              </div>
              {list.length > 0 ? (
                <div className="sheets__item">
                  <div className="sheets__item-text">
                    {listItems}
                  </div>
                </div>
              ) : (
                <div className="sheets__item">
                  <div className="sheets__item-text">
                    {dayItems}
                  </div>
                </div>
              )}
              <div className="sheets__send">
                <button onClick={onSub}>
                  Абонементы
                </button>
                <button onClick={onDay}>
                  Дни рождения
                </button>
                {/* <button onClick={onSend}>
                  Письмо
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
